// material-ui
import { Button, Stack, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormHelperText } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import axiosInstance from 'interceptors/axiosInstance';
import { createClientAPI, createEstimationAPI, menuAPI } from 'api/api';
import Notification from "../../../ui-component/notification/notification";
import Swal from 'sweetalert2';
import { isText, checkStartSpecialChar } from 'views/versions/tabs/Constfunctions';
import { AppContext } from 'App';
import { useNavigate } from 'react-router';
// ==============================|| SAMPLE PAGE ||============================== //

const Dashboard = () => {

  const [openModal, setOpenModal] = useState(false);
  const [client, setClient] = useState('');
  const [items, setItems] = useState([]);
  const [estimation, setEstimation] = useState('')
  const [estimationDes, setEstimationDes] = useState('');
  const [opprtunityType, setOpportunityType] = useState('');
  const [error, setError] = useState({
    clientError: false,
    estimateError: false,
    estimateTypeError: false,
    estimateDesError: false,
  });

  const navigate = useNavigate();

  // for adding new client
  const [openClientModal, setOpenClientModal] = useState(false);
  const [newClient, setNewClient] = useState('');
  const [newClientError, setNewClientError] = useState(false);

  // Consumer of AppContext API
  const { setMenuValue } = useContext(AppContext);
  const [setRefreshMenus] = setMenuValue;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setClient('');
    setOpportunityType('');
    setOpenModal(false);
    setError({ clientError: false, estimateError: false, estimateTypeError: false, estimateDesError: false });
  };

  const handleClientOpenModal = () => {
    setOpenClientModal(true);
  }

  const handleClientCloseModal = () => {
    setNewClient('');
    setOpenClientModal(false);
    setNewClientError(false);
  };


  const handleClientChange = (e) => {
    setClient(e.target.value);

    if (e.target.value !== '') {
      setError({ ...error, clientError: false })
    }

  }

  const handleOpprtunityTypeChange = (e) => {
    setOpportunityType(e.target.value);

    if (e.target.value !== '') {
      setError({ ...error, estimateTypeError: false })
    }

  }

  const handleEstimationChange = (e) => {
    setEstimation(e.target.value);
    if (e.target.value !== '' && e.target.value.length !== 0 && isText(e.target.value)) {
      setError({ ...error, estimateError: false });
    }
    else {
      setError({ ...error, estimateError: true });
    }

  }

  const handleEstimationDesChange = (e) => {
    setEstimationDes(e.target.value);
    if (e.target.value !== '' && e.target.value.length !== 0 && isText(e.target.value)) {
      setError({ ...error, estimateDesError: false });
    }
    else {
      setError({ ...error, estimateDesError: true });
    }

  }

  const handleNewClientChange = (e) => {
    setNewClient(e.target.value);
    if (e.target.value !== '' && e.target.value.length !== 0 && isText(e.target.value)) {
      setNewClientError(false);
    }
    else {
      setNewClientError(true);
    }
  }

  const [openNotification, setOpenNotification] = useState(false);
  const [openClientNotification, setOpenClientNotification] = useState(false);
  const handleAddOpportunity = async (e) => {
    e.preventDefault();
    var isDublicate = false;
    client.Items.forEach(ele => {

      if (estimation.trim() === ele.Text.trim()) {
        isDublicate = true;
        return;
      }
    });


    if (isDublicate) {
      handleCloseModal();
      Swal.fire("Opportunity already exist!");
    }
    else {
      await axiosInstance.post(createEstimationAPI, { ClientId: client.Key, EstimationName: estimation, EstimationDescription: estimationDes, OpportunityType: opprtunityType }).then((res) => {
        setRefreshMenus((refreshMenus) => refreshMenus + 1);
        handleCloseModal();
        setOpenNotification(true);
        navigate(`/estimation?ClientId=${client.Key}&ProjectId=${res.data.Data}`);
      }).catch((error) => console.log(error));
    }

  }


  const handleAddNewClient = async (e) => {
    e.preventDefault();
    var isDublicate = false;

    items.forEach(ele => {
      if (newClient.trim() === ele.Text.trim()) {
        isDublicate = true;
        return;
      }
    });

    if (isDublicate) {
      handleClientCloseModal();
      Swal.fire("Client already exist!");
    }
    else {
      await axiosInstance.post(createClientAPI, {ClientName: newClient, ClientDescription: newClient}).then(() => {
        setRefreshMenus((refreshMenus) => refreshMenus + 1);
        handleClientCloseModal();
        setOpenClientNotification(true);
        
      }).catch((error) => console.log(error));
    }

  }

  const disablAddBtn = useMemo(() => {
    return client.length === 0 || estimation.length === 0 || opprtunityType.length === 0 || estimationDes.length === 0 || error.clientError || error.estimateError || error.estimateDesError;
  }, [client, estimation, opprtunityType, estimationDes]);

  const disableAddClientBtn = useMemo(() => {
    return newClient.length === 0 || newClientError;
  }, [newClient]);

  useEffect(() => {
    const fetchData = async () => {
      await axiosInstance.get(menuAPI).then((response) => {
        setItems(response.data);
      }).catch((error) => console.log(error));
    }
    fetchData();
  }, [openModal]);


  return (
    <MainCard>
      <Notification
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        message={'Opportunity Created Successfully'}
      />
      <Notification
        openNotification={openClientNotification}
        setOpenNotification={setOpenClientNotification}
        message={'Client Created Successfully'}
      />

      <Stack direction="row" alignItems="center" justifyContent="right" spacing={1}>
        <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleClientOpenModal}>
          Create Client
        </Button>
        <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleOpenModal}>
          Create Opportunity
        </Button>
      </Stack>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          component: 'form',
          onSubmit: handleAddOpportunity
        }}
      >
        <DialogTitle sx={{ fontSize: '1.5rem' }}>Add New Opportunity</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <Box
            noValidate
            component="form"
            sx={{
              width: '100%'
            }}
          >
            <FormControl error={error.clientError} variant="filled" sx={{ mt: 1, minWidth: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">Client</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="client"
                value={client}
                onChange={handleClientChange}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setError({ ...error, clientError: true });
                  }
                }}
              >
                {items.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item.Text}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText sx={{ color: '#f44336' }}>{error.clientError ? 'Invalid input' : ''}</FormHelperText>
            </FormControl>

            <TextField
              error={error.estimateError}
              id="Opportunity-Name"
              label="Opportunity Name"
              variant="filled"
              sx={{ mt: 1, minWidth: '100%' }}
              onChange={handleEstimationChange}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setError({ ...error, estimateError: true });
                }
              }}
              onKeyDown={checkStartSpecialChar}
            />

            <FormHelperText sx={{ color: '#f44336' }}>{error.estimateError ? 'Invalid input' : ''}</FormHelperText>

            <FormControl error={error.estimateTypeError} variant="filled" sx={{ mt: 1, minWidth: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">Client Type</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="Opprtinity-Type"
                value={opprtunityType}
                onChange={handleOpprtunityTypeChange}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setError({ ...error, estimateTypeError: true });
                  }
                }}
              >
                <MenuItem value={1}>New</MenuItem>
                <MenuItem value={0}>Existing</MenuItem>
              </Select>
              <FormHelperText sx={{ color: '#f44336' }}>{error.estimateTypeError ? 'Invalid input' : ''}</FormHelperText>
            </FormControl>

            <TextField
              error={error.estimateDesError}
              id="Opportunity-Description"
              label="Opportunity Description"
              variant="filled"
              sx={{ mt: 1, minWidth: '100%' }}
              onChange={handleEstimationDesChange}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setError({ ...error, estimateDesError: true });
                }
              }}
              onKeyDown={checkStartSpecialChar}
            />

            <FormHelperText sx={{ color: '#f44336' }}>{error.estimateDesError ? 'Invalid input' : ''}</FormHelperText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="secondary" disabled={disablAddBtn}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openClientModal}
        onClose={handleClientCloseModal}
        PaperProps={{
          component: 'form',
          onSubmit: handleAddNewClient
        }}
      >
        <DialogTitle sx={{ fontSize: '1.5rem' }}>Add New Client</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <Box
            noValidate
            component="form"
            sx={{
              width: '100%'
            }}
          >
            <FormControl error={newClientError} variant="filled" sx={{ mt: 1, minWidth: '100%' }}>
              <TextField
                error={newClientError}
                id="Client-Name"
                label="Client Name"
                variant="filled"
                sx={{ mt: 1 }}
                onChange={handleNewClientChange}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setNewClientError(true);
                  }
                }}
                onKeyDown={checkStartSpecialChar}
              />

              <FormHelperText sx={{ color: '#f44336' }}>{newClientError ? 'Invalid input' : ''}</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClientCloseModal} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="secondary" disabled={disableAddClientBtn}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
}

export default Dashboard;
