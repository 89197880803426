import React, { useEffect, useState } from 'react';
import './Estimation.css';

import { Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { formatDate } from 'views/versions/tabs/Constfunctions';
import axiosInstance from 'interceptors/axiosInstance';
import { estimationAPI, createEstimationVersionAPI, deleteEstimationVersionAPI,updateEstimationStatus } from 'api/api';
import Swal from 'sweetalert2';
import Spinnerprogress from 'ui-component/circular-progress/Spinnerprogress';


const Estimation = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [refreshRows, setRefreshRows] = useState([]);
  const [status,setStatus]=useState("")

  const ClientId = searchParams.get('ClientId');
  const ProjectId = searchParams.get('ProjectId');


  const handleCreateVersion = async (e) => {
    e.preventDefault();

    await axiosInstance.post(createEstimationVersionAPI, { ClientId: ClientId, ProjectID: ProjectId, EstimateName: "", EstimateComment: "" }).then((response) => {
      setRefreshRows(response.data);
      console.log(response.data);
    }).catch((error) => console.log(error));

  }

  const handleDeleteVersion = (EstimationId, Version) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedRow = rows.filter(r => r.EstimationID !== EstimationId);
        setRows(updatedRow);
        await axiosInstance.delete(deleteEstimationVersionAPI, { data: { ClientId: ClientId, ProjectID: ProjectId, EstimationId: EstimationId, Version: Version } }).catch((error) => console.log(error))
        setRefreshRows(updatedRow);
      }
    });
  }

  const checkEstimationID = () => {
    var r = true
    rows.forEach(e => {
      if (e.EstimationID === null) {
        r = false
      }

    })
    return r

  }


  const disbleVersionBtn = () => {
    var result = true;
    if (rows.length === 0) return false;
    if (rows[0].IsComplete === null || rows[0].IsComplete === true) {
      result = false
    }
    return result;
  }
  
  const handleStatusChange = async(event) => {
    setStatus(event.target.value);
    await axiosInstance.put(updateEstimationStatus, {ClientId:ClientId,ProjectID:ProjectId,Status:event.target.value}).catch((error) => console.log(error));
  };



  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await axiosInstance.get(`${estimationAPI}?ClientId=${ClientId}&ProjectId=${ProjectId}`).catch(err => console.log(err));
      setRows([...response.data]);
      response.data[0].Status===null?setStatus(''):setStatus(response.data[0].Status);
      // console.log(response.data[0].Status)
      setIsLoading(false);
    }
    fetchData();

  }, [searchParams, refreshRows]);

  return (
    <>
      {isLoading ? <Spinnerprogress /> :
        <Card sx={{ p: 2 }}>
          {/* <Notification publishNotification={publishNotification} setPublishNotification={setPublishNotification} message={" Estimation Published Successfully"}/> */}


          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ mb: 2 }}>

            {rows.length === 0 ? '' : <Typography variant='h3' sx={{ fontWeight: 'bold', marginBottom: '1em' }} color="secondary">
              {rows[0].OpportunityName} - <span style={{ fontWeight: 'normal' }}>{rows[0].OpportunityDescription}</span>
            </Typography>}

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  <MenuItem value={"Open"}>Open</MenuItem>
                  <MenuItem value={"Won"}>Closed-Won</MenuItem>
                  <MenuItem value={"Lost"}>Closed-Lost</MenuItem>
                </Select>
            </FormControl>
                

            <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleCreateVersion} disabled={disbleVersionBtn()}>
              {(rows.length === 0 || !checkEstimationID()) ? "Create Estimate" : "Create Version"}
            </Button>
          </Stack>

          {
            (rows.length === 0 || !checkEstimationID()) ? "" : <TableContainer>
              <Table aria-label="simple table" className="estimation-table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created Date / Time </TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {parseFloat('1.' + row.Version)}
                      </TableCell>
                      <TableCell align="right">{row.EstimateName}</TableCell>
                      <TableCell align="right">{row.UpdatedBy}</TableCell>
                      <TableCell align="right">{formatDate(row.Updated)}</TableCell>
                      <TableCell align="right">{row.EstimateComment}</TableCell>
                      <TableCell align="right">
                        <Typography variant="h5" color={row.IsComplete ? 'success.dark' : 'primary'}>
                          {row.IsComplete ? 'Published' : 'Draft'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                              m: 1
                            },
                            cursor: 'pointer'
                          }}
                        >
                          <Link to={`/estimation/view?ClientId=${ClientId}&ProjectId=${ProjectId}&EstimationId=${row.EstimationID}&Version=${row.Version}`}>
                            <VisibilityIcon />
                          </Link>
                          {!row.IsComplete ? (
                            <>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Link to={`/estimation/edit?ClientId=${ClientId}&ProjectId=${ProjectId}&EstimationId=${row.EstimationID}&Version=${row.Version}`}>
                                <EditIcon />
                              </Link>
                              <Divider orientation="vertical" variant="middle" flexItem />

                              <DeleteIcon onClick={() => handleDeleteVersion(row.EstimationID, row.Version)} />

                            </>
                          ) : (
                            ''
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">{row.actions}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          }
        </Card>
      }
    </>
  );
};

export default Estimation;
