import * as React from 'react';
import { Box, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Assumption from './Assumption';
import Question from './Question';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useState, useEffect } from 'react';
import { PublishModal } from "../../component/PublishModal";
import { getAssumptionDataAPI, getQuestionDataAPI,publishValidationAPI } from 'api/api';
import Spinnerprogress from 'ui-component/circular-progress/Spinnerprogress';
import axiosInstance from 'interceptors/axiosInstance';

export default function AssumptionQuestion({ view, ClientId, ProjectId, EstimationId, Version,setTab }) {
  const [value, setValue] = useState('1');
  const[open , setOpen]=useState(false);
  const [assumptionData, setAssumptionData] = useState([]);
  const [questionData, setQusetionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[canPublish,setCanPublish]=React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBack = (e) => {
    e.preventDefault();
    setTab('Annual-Support-Cost');
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      // For Asuumption data
      await axiosInstance.get(`${getAssumptionDataAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).then((response) => {
        setAssumptionData([...response.data]);
      }).catch((error) => console.log(error));

      // For Question data
      await axiosInstance.get(`${getQuestionDataAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`).then((response) => {
        setQusetionData([...response.data]);
      }).catch((error) => console.log(error));

      // api for publish validation
      await axiosInstance.get(`${publishValidationAPI}?EstimationId=${EstimationId}`).then((response)=>{
        let temp=canPublish;
        // console.log(temp);
        response.data.map((validationElement)=>{
          temp[validationElement.Metric]=validationElement.PublishAllowed
        });

        setCanPublish(temp);
      }).catch((error) => console.log(error));
    
      setIsLoading(false);
    }
    fetchData();
   
  }, []);
  
  return (
    <>
      {isLoading ? <Spinnerprogress /> :
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} textColor="secondary"
                indicatorColor="secondary" aria-label="lab API tabs example">
                <Tab label="Assumptions" value="1" />
                <Tab label="Questions" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"><Assumption view={view} initialRows={assumptionData}
              ClientId={ClientId} ProjectId={ProjectId} EstimationId={EstimationId} Version={Version}
            /></TabPanel>
            <TabPanel value="2"><Question view={view} initialRows={questionData}
              ClientId={ClientId} ProjectId={ProjectId} EstimationId={EstimationId} Version={Version}
            /></TabPanel>
          </TabContext>
          <Button size="1.3rem" type="button" variant="contained" color="secondary" onClick={handleBack} sx={{ marginTop: '20px',marginRight:'20px', visibility: view ? 'hidden' : 'visible' }}>
              <NavigateBeforeIcon/>Back
          </Button>
          <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '20px' , visibility: view ? 'hidden' : 'visible' }} onClick={()=>setOpen(true)}>
            Review & Publish
          </Button>
        <PublishModal open={open} canPublish={canPublish} ClientId={ClientId} ProjectId={ProjectId} EstimationId={EstimationId} Version={Version} closeModal={() => {
          setOpen(false);
         }}/>
        
        </Box>
      }
    </>
  );

}