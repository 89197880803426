import { useState, useEffect } from "react";
import EditableTable from "./EditTable";
import EditableTableCell from '../../component/EditableTableCell';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from "@mui/material";
import { Button, Box } from "@mui/material";
import { currencyFormatter, SumofCoulmn } from "../Constfunctions";
import { GetBuildTeamAPI, updateBuildTeamAPI } from "api/api";
import Spinnerprogress from "ui-component/circular-progress/Spinnerprogress";
import Notification from "../../../../ui-component/notification/notification";
import axiosInstance from "interceptors/axiosInstance";

const BuildSetupCost = ({ view, setBuildSetupCost, ClientId, ProjectId, EstimationId, Version, setDirty, setNotDirty}) => {

    const weeklyHour = 40;
    const [estimatedHour, setEstimatedHour] = useState(0);
    const [estimatedContengency, setEstimatedContengency] = useState(0);
    const[contengencyPrecentage,setContengencyPrecentage]=useState(0)
    const [totalHour, setTotalHour] = useState(0);
    const [hour,setHour] = useState(0);

    const [rows, setRows] = useState([]);
    const [resource, setResource] = useState(1);
    const [week, setWeek] = useState(0);
    const [totalcost, settotalcost] = useState(0);
    const [totaleffort, settotaleffort] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedFeild, setUpdatedFeild] = useState({

        FTE1: 0,
        Comment1: "",
        CostHrRate1: 0,
        FTE2: 0,
        Comment2: "",
        CostHrRate2: 0,
        FTE3: 0,
        Comment3: "",
        CostHrRate3: 0,
        FTE4: 0,
        Comment4: "",
        CostHrRate4: 0,
        FTE5: 0,
        Comment5: "",
        CostHrRate5: 0,
        FTE6: 0,
        Comment6: "",
        CostHrRate6: 0,
        FTE7: 0,
        Comment7: "",
        CostHrRate7: 0,
        Weeks: 0,
        BilledHours:0,
        EstimatedContengency:0
    })


    useEffect(() => {
        setIsLoading(true);

        const fetchBuildCostData = async () => {
            await axiosInstance.get(`${GetBuildTeamAPI}?ClientId=${ClientId}&ProjectID=${ProjectId}&EstimationId=${EstimationId}&Version=${Version}`)
                .then((response) => {
                    const initialRow=response.data.DigitalData;
                    const finalRow=[initialRow[5],initialRow[6],initialRow[0],initialRow[1],initialRow[2],initialRow[3],initialRow[4]]
                    setRows(finalRow);
                    const estimationhour=response.data.IntermediateCalculations[0].value
                    setEstimatedHour(estimationhour);
                    setResource(initialRow[5].FTE);
                    const contencgencyPrecentage=response.data.IntermediateCalculations[1].value
                    setContengencyPrecentage(contencgencyPrecentage);
                    const contengency=Math.ceil(estimationhour*contencgencyPrecentage/100)
                    setEstimatedContengency(contengency)
                    const totalHour = estimationhour+ contengency
                    setTotalHour(totalHour);
                    const hour=response.data.IntermediateCalculations[3].value;
                    setHour(hour);
                    if(initialRow[5].FTE ==0) setWeek(0);
                    else setWeek(Math.round(totalHour / initialRow[5].FTE / hour));
                    
                    setIsLoading(false);
                    setBuildSetupCost(SumofCoulmn(initialRow, 'Cost'));
                    
                }).catch((error) => console.log(error));
                

        }
        fetchBuildCostData();
        

    }, []);

    useEffect(() => {

        settotalcost(SumofCoulmn(rows, 'Cost'));
        settotaleffort(SumofCoulmn(rows, 'Effort'));
       
        if (rows.length > 0) {
            setUpdatedFeild({
                FTE1: rows[2].FTE, Comment1: rows[2].Comments, CostHrRate1: rows[2].CostHrRate,
                FTE2: rows[3].FTE, Comment2: rows[3].Comments, CostHrRate2: rows[3].CostHrRate,
                FTE3: rows[4].FTE, Comment3: rows[4].Comments, CostHrRate3: rows[4].CostHrRate,
                FTE4: rows[5].FTE, Comment4: rows[5].Comments, CostHrRate4: rows[5].CostHrRate,
                FTE5: rows[6].FTE, Comment5: rows[6].Comments, CostHrRate5: rows[6].CostHrRate,
                FTE6: rows[0].FTE, Comment6: rows[0].Comments, CostHrRate6: rows[0].CostHrRate,
                FTE7: rows[1].FTE, Comment7: rows[1].Comments, CostHrRate7: rows[1].CostHrRate,
                Weeks: week,
                BilledHours:hour,
                EstimatedContengency:contengencyPrecentage
            })


        }

    }, [rows]);

    const handleCellChange = (newValue, rowIndex, columnIndex) => {
        setDirty();
        if (columnIndex === 1 && rowIndex != 0) {
            //changes in fte column
            setRows(rows.map((currRow, idx) => {
                if (idx !== rowIndex) return currRow;
                else {
                    const tempEffort = newValue * weeklyHour * week;
                    const tempCost = Math.round(tempEffort * currRow["CostHrRate"] / 10) * 10;
                    return ({ ...currRow, "FTE": newValue, "Effort": tempEffort, "Cost": tempCost });
                }

            }));


        }
        else if (columnIndex === 1 && rowIndex == 0) {
            //change is in fte column developer offshore so resource value is change thats and week value is depended on resource
            setResource(newValue);
            const tempresource = newValue;
            // console.log(totalHour, tempresource, hour)
            let tempweek = 0;
            if(tempresource!=0){
                tempweek = Math.round(totalHour / tempresource / hour);
            }
            setWeek(tempweek);

            const rate = [1, 0.5, 0.25, 0, 0, 0, 0];
            setRows(rows.map((currRow, idx) => {
                const tempfte = newValue * rate[idx];
                const tempeffort = tempfte * weeklyHour * tempweek;
                const tempcost = Math.round(tempeffort * currRow['CostHrRate'] / 10) * 10;
                return ({ ...currRow, "Effort": tempeffort, "FTE": tempfte, "Cost": tempcost });
            }));


        }
       
        else if (columnIndex === 3) {
            //changes in cost hr Rate column
            setRows(rows.map((currRow, idx) => {
                if (idx !== rowIndex) return currRow;
                else {
                    const tempCost = Math.round(currRow["Effort"] * newValue / 10) * 10;
                    return ({ ...currRow, "CostHrRate": newValue, "Cost": tempCost });
                }

            }));

        }

        else if(rowIndex===11 && columnIndex===4){
            // changes in hour value
            let temphour=newValue;
            setHour(temphour);
            let tempweek = 0;
            if(temphour!=0 && resource!=0){
                tempweek = Math.round(totalHour / resource / temphour);
            }
            setWeek(tempweek);
            // as the value of week changes the efforts in each row of the first table changes.
            setRows(rows.map((currRow) => {
                const tempeffort = currRow['FTE'] * weeklyHour * tempweek;
                const tempcost = Math.round(tempeffort * currRow['CostHrRate'] / 10) * 10;
                return ({ ...currRow, "Effort": tempeffort, "Cost": tempcost });
            }));
           
        }
        else if(rowIndex===11 && columnIndex===2){
            // changes in contengency value
            let tempContegencyPrecentage=newValue;
            let contengency=Math.ceil(estimatedHour*tempContegencyPrecentage/100)
            let temptotalhour=contengency+estimatedHour;
            setContengencyPrecentage(tempContegencyPrecentage)
            setEstimatedContengency(contengency);
            setTotalHour(temptotalhour);
            let tempweek=0;
           if(resource==0 || hour==0){
            setWeek(tempweek);
           } 
           else{
            tempweek=Math.round(temptotalhour / resource / hour);
            setWeek(tempweek);
           }
            
            // as the value of week changes the efforts in each row of the first table changes.
            setRows(rows.map((currRow) => {
                const tempeffort = currRow['FTE'] * weeklyHour * tempweek;
                const tempcost = Math.round(tempeffort * currRow['CostHrRate'] / 10) * 10;
                return ({ ...currRow, "Effort": tempeffort, "Cost": tempcost });
            }));
           
        }
       
        else {
            setRows(rows.map((currRow, idx) => {
                //changes in comment column
                if (idx !== rowIndex) return currRow;
                else {
                    return ({ ...currRow, "Comments": newValue });
                }

            }));

           
        }


    };
    const [openNotification, setOpenNotification] = useState(false);
    const handleSave = async (e) => {
        e.preventDefault();
        setOpenNotification(true);
        // validation check to publish
        // updatedFeild.FTE6 ?setCanPublish({...canPublish, BuildTeamcostTab:"True"}):setCanPublish({...canPublish, BuildTeamcostTab:"False"});
        await axiosInstance.post(updateBuildTeamAPI, { ...updatedFeild, EstimationId: EstimationId, Version: Version }).catch((error) => console.log(error));
        setBuildSetupCost(SumofCoulmn(rows, 'Cost'));
        setNotDirty();
    }

    return (
        <>
            {isLoading ? <Spinnerprogress /> :
                <>
                    <Notification openNotification={openNotification} setOpenNotification={setOpenNotification} message={" Saved Successfully"}/>
                    <EditableTable rows={rows} handleCellChange={handleCellChange} totalcost={totalcost} totaleffort={totaleffort} weeks={week} view={view} setDirty={setDirty} weekhide={false}/>
                    <Button size="1.3rem" type="button" variant="contained" color="secondary" sx={{ marginTop: '20px', visibility: view ? 'hidden' : 'visible' }} onClick={handleSave}>
                        Save
                    </Button>
                    <Box sx={{ marginTop: '30px', marginBottom: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
                        <TableContainer>
                            <Table>
                                <TableHead className='table__header'>
                                    <TableRow>
                                        <TableCell colSpan={2}>Intermediate calculations</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{width: '70%'}}>Estimated Hour</TableCell>
                                        <TableCell className="text__right">{estimatedHour}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Contingency Percentage</TableCell>
                                        <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={contengencyPrecentage} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 11, 2)
                                                 // to change contigency value
                                            }
                                        /></TableCell>
                                        
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Estimated Contingency</TableCell>
                                        <TableCell className="text__right">{estimatedContengency}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total Hour</TableCell>
                                        <TableCell className="text__right">{totalHour}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer>
                            <Table>
                                <TableHead className='table__header'>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell className="text__right" sx={{width: '25%'}}>Hour</TableCell>
                                        <TableCell className="text__right" sx={{width: '25%'}}>Resource</TableCell>
                                        <TableCell className="text__right">Week</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Duration</TableCell>
                                        <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={hour} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 11, 4)// to change hour value
                                        
                                            }
                                        /></TableCell>
                                        <TableCell className="text__right editable__feild"><EditableTableCell
                                            view={view}
                                            value={resource} 
                                            dollarSign="false"
                                            type="number"
                                            onChange={(newValue) =>
                                                handleCellChange(newValue, 5, 1)
                                            }
                                        />
                                        </TableCell>
                                        <TableCell className="text__right">{week}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead className='table__header'>
                                <TableRow>
             
                                    <TableCell colSpan={2} style={{textAlign: 'center', textTransform: 'uppercase'}}>TOTAL COST</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Setup Costs</TableCell>
                                    <TableCell className="text__right">{currencyFormatter(totalcost)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Setup Schedule - in Wks</TableCell>
                                    <TableCell className="text__right">{week}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </>
                
            }
        </>
    );
};

export default BuildSetupCost;
