// material-ui
import { Typography } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
// project imports
import NavGroup from './NavGroup';
import axiosInstance from 'interceptors/axiosInstance';
import { menuAPI } from 'api/api';
import home from 'menu-items/Home';
import logout from 'menu-items/logout';
import { AppContext } from 'App';


// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [menus, setMenus] = useState({
    "Key": 'home',
    "Type": 'group',
    "Items": []
  });

  const menuItem = {
    items: [home, menus, logout]
  };

  // Consumer of AppContext API
  const { menuValue } = useContext(AppContext);
  const [refreshMenus] = menuValue;


  useEffect(() => {
    // console.log(refreshMenus);
    const fetchMenu = async () => {
      await axiosInstance.get(menuAPI).then((response) => {
        setMenus({ ...menus, "Items": response.data });
      }).catch(error => console.log(error));
    }
    fetchMenu();


  }, [refreshMenus]);



  const navItems = menuItem.items.map((item) => {
    switch (item.Type) {
      case 'group':
        return <NavGroup key={item.Key} item={item}/>;
      default:
        return (
          <Typography key={item.Key} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });



  return <>{navItems}</>;
};

export default MenuList;
